html,body, #root {
  height: 100%;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('./asset/img/logictics.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
html,body div {
  box-sizing: border-box;
  font-size: 17px;
}
.MuiBackdrop-root {
  opacity: 0!important;
}
.App {
  text-align: center;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
.search-field input {
  padding: 10px!important;
}
.App.login,
.App.forgotPwd {
  width: 80%;
  max-width: 450px;
    background-color: #ffffff;
    padding: 30px;
    box-sizing: border-box;
}

.App.register {
  width: 80%;
  max-width: 650px;
  background-color: #ffffff;
  padding: 30px;
    box-sizing: border-box;
}
.App:not(.register):not(.login):not(.forgotPwd) {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #fcfcfc;
}

main {
  display: flex;
  flex-direction: column;
}

.main-content {
  padding-top: 10px;
  margin: 0 auto;
  max-width: 90%;
  width: 1400px;
}

.page-header {
  justify-content: space-between;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}
.page-title {
  font-weight: 500;
  text-transform: uppercase;
  text-align: left;
  font-size: 20px;
  color: #ca5a2f;
  flex-grow: 1;
}

.btn-text{
  text-transform: capitalize!important;
}

.btn-icon {
  min-width: 30px!important;
  width: 45px;
  margin: 5px!important;
}

.lb-m {
  width: 150px;
  text-align: right;
  padding-right: 30px;
}
.search-filter .MuiDrawer-paperAnchorRight {
  z-index: 1202;
  width: 350px;
}
.close-icon-btn {
  width: 30px;
  height: 30px;
  font-size: 18px!important;
    color: rgb(80 141 32)!important;
}
.MuiFormControl-root {
  margin-bottom: 15px!important;
}
.customer-list-appbar .MuiFilledInput-root {
  background-color: transparent!important;
}

.customer-list-appbar .MuiFilledInput-root::before {
  border-bottom: 0
}

.customer-list-appbar .MuiFilledInput-root .MuiNativeSelect-select {
  font-weight: "bold!important";
  color: #000;
  text-align: center;
  padding-top: 12px;
  background: transparent!important;
}

.App:not(.sale):not(.driver) .MuiBox-root {
  flex: 1;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-nowrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.cursor-pointer {
  cursor: pointer;
}
.fullWidth{
  width: 100%;
  flex: 1 1 auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
}
.flex-row .con-item:not(.datepick-wrapper) {
  width: 250px;
}

input[type="text"],
textarea {
  width: 100%;
  border: 0;
  border-radius: 0;
  outline: none;
  /*text-transform: capitalize;*/
}

.search-bar {
  display: flex;
  padding-bottom: 10px;
  background-color: #fcfcfc;
  padding: 25px 15px;
  flex-wrap: wrap;
  overflow: hidden;
}

.search-bar-con.collapse {
  display: none;
  transition: ease-in 0.3s;
}
.MuiDialog-paperWidthSm {
  max-width: 900px!important;
}
.circle-btn {
  width: 40px;
  height: 40px;
  min-width: 40px!important;
  border-radius: 50%!important;
  visibility: hidden;
}
.list-item:hover {
  background-color: #eee;
}
.list-item:hover .circle-btn {
  visibility: visible;
}
.hidden {
  display: none!important;
}
.dropdown {
  position: relative;
}
.dropdown .dropdown-content {
    position: absolute;
    background: #fff;
    z-index: 999;
    border: 1px solid #ccc;
    box-shadow: 4px 4px 4px #666;
    max-height: 350px;
    overflow: auto;
    width: 100%;
}
.dropdown .dropdown-content li {
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  width: 100%;
  outline: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.dropdown .dropdown-content li:hover,
.dropdown .dropdown-content li.active {
  background-color: #dcedc8;
}
.MuiListItemAvatar-root {
  min-width: 40px!important;
  align-items: center;
  display: flex;
}
.datepick-wrapper,
.search-bar .con-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.react-datepicker-popper {
  z-index: 99!important;
}
.search-bar .datepick-wrapper input[type="text"] {
  height: 31px;
    background-color: transparent;
    font-size: 15px;
    font-weight: bold;
    border-color: #888;
}
.search-bar .datepick-wrapper label {
  font-size: 12px;
  font-weight: bold;
}
.datepick-wrapper {
  max-width: 110px;
}

.khuvuc-dialog .dialog-popup{
  width: 450px;
  overflow: visible;
}
.khuvuc-dialog .MuiDialogContent-root,
.khuvuc-dialog .MuiPaper-root {
  overflow: visible!important;
}



.search-bar .con-item {
  margin-right: 20px;
  margin-top: 20px;
}

.search-bar .con-item.ds,
.search-bar .con-item.search {
  width: 250px;
}

.dialog-wrapper,
.dialog-wrapper .dialog-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  left: 0;
}

.dialog-wrapper .dialog-layer {
  opacity: 0.3;
  position: absolute;
  background-color: #333;
}

.dialog {
  max-width: 500px;
  background-color: #fff;
  margin: auto;
  z-index: 9999;
  border-radius: 15px;
  width: 100%;
  box-sizing: border-box;
}

.dialog-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #999;
    height: 60px;
    margin-bottom: 20px;
    align-items: center;
 }

 .dialog-header .title {
  flex: 1 1 auto;
  text-align: left;
  box-sizing: border-box;
  padding-left: 15px;
  align-items: center;
  display: flex;
 }

.dialog-header button {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  box-sizing: border-box;
  min-width: 30px;
  position: relative;
  top: -2px;
}

.dialog-content {
  padding: 20px;
}

.datepick-wrapper.standard {
  min-width: 195px;
}

.datepick-wrapper.standard input[type="text"] {
  height: 30px;
}
span.h-space {
  width: 15px;
}
span.v-space {
  height: 25px;
}
.flex {
 display: flex; 
}
.toolbar-func {
  color: #e57373;
    width: 100%;
    display: flex;
    padding: .75em .5em;
    align-items: center;
    font-weight: bold;
    justify-content: flex-start;

    position: fixed;
    z-index: 999;
    top: 60px;
}

.sub-tool-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
}

.sub-tool-bar-btn {
  cursor: pointer;
}

.dialog-popup .inp-fld {
  min-width: 45%;
  padding-right: 5%;
  margin-bottom: 20px;
}


.nv-detail,
.cus-detail {
  padding: 30px;
  max-width: 768px;
}
.toolbar-func button {
  margin: 0 10px 10px 0;
}
.toolbar-func button:not(.btn-text) {
  color: #fff;
    width: 40px;
    height: 40px;
    min-width: 30px;
}
.alignItems-center {
  align-items: center;
}
.alignItems-left {
  align-items: flex-start;
}
.alignItems-right {
  align-items: flex-end;
}
.padding30 {
  padding: 30px;
}
.grid-data {
  min-height: 500px;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
justify-content: flex-start;
}
.MuiDataGrid-root {
  padding-bottom: 60px!important;
}
.MuiDataGrid-root .MuiDataGrid-footerContainer,
.MuiTablePagination-root {
  /*position: fixed;*/
  background-color: #fafafa;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid #999;
}

.summary-bar .MuiChip-root {
  margin-left: 15px;
  font-size: 18px;
}
.summary-bar .MuiBadge-badge  {
  font-size: 15px;
  font-weight: bold;
}

.summary-bar .MuiBadge-badge .MuiBadge-anchorOriginTopRightRectangle {
  top: -7x;
  right: 7px;
}

.moduleTitle {
  flex: 1 1 auto;
  justify-content: center;
  font-size: 2em!important;
  color: #fff
}

.orders-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.orders-main-content.taixe.SHIPPER {
  padding-top: 115px;
}
.user-loggedin {
  position: relative;
}

.sp-bt {
  justify-content: space-between;
  position: relative;
} 

.max450 {
  max-width: 450px;
}

.dialog-a-visible .MuiDialog-paper,
.dialog-a-visible .MuiDialog-paper .MuiDialogContent-root {
  overflow: visible!important;
}


.edit-btn-float {
  position: absolute!important;
  visibility: hidden;
  right: -35px;
  top: -8px;
}

.dg-kv .sp-bt:hover .edit-btn-float {
visibility: visible;
}

@media screen and (max-width: 680px) {
  .App, #root {
    background-image: none!important;
  }

  .App.login, .App.forgotPwd, .App.register {
    width: 100%;
  }

}